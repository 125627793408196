<template>
  <v-container>
		<v-card>
			<v-toolbar flat dark color="primary">
				<v-toolbar-title>
					<h3>Upload from IS</h3>
				</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<h4>Choose an Upload Type:</h4>
				<v-radio-group
					v-model="upload_type"
					row
				>
					<v-radio
						label="Payroll Data"
						value="payroll"
					></v-radio>
					<v-radio
						label="Transaction Data"
						value="trx"
					></v-radio>
				</v-radio-group>
				<div class="upload-files-container" v-if="upload_type !== ''">
					<h4>Select files to upload:</h4>
					<div class="upload-files large-12 medium-12 small-12 cell">
						<label>
							<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
						</label>
					</div>
					<br>
					<v-btn color="primary" dark @click.stop="confirmDialog = true" :disabled="role_access===1">
						<v-icon dark left>mdi-upload-multiple</v-icon>
						Upload Files
					</v-btn>
				</div>
				<br>
				<hr>
				<br>
				<h3>Uploaded Entries:</h3>
				<br>
				<v-data-table
					:headers="headers"
					:items="csvFileUploads.data"
					:items-per-page="30"
					hide-default-footer
					dense
					class="elevation-1"
				>
				<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(csvFileUploads.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(csvFileUploads.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{csvFileUploads.current_page}} ( {{csvFileUploads.from}} - {{csvFileUploads.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!csvFileUploads.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!csvFileUploads.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
				</v-data-table>
			</v-card-text>
    </v-card>
		<div class="text-center">
			<v-dialog
				v-model="confirmDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Upload Files
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to upload the selected files?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="executeUpload"
						>
							Yes
						</v-btn>
						<v-btn
							color="red"
							text
							@click="confirmDialog = false"
						>
							No
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
  </v-container>
</template>
<script>
import jsonToFormData from 'json-form-data';
import { mapGetters } from 'vuex';
 
export default {
	name: 'UploadFromIS',
	data() {
		return {
			module_name: 'Upload from IS',
			role_access: 1,
			upload_type: '',
			upload_files: '',
			headers: [
				{ text: 'File Name', value: 'csv_filename' },
				{ text: 'Date Uploaded', value: 'upload_date' },
				{ text: 'Status', value: 'status' },
				{ text: 'Uploaded By', value: 'upload_by' },
			],
			confirmDialog: false,
			isFileValid: false,
			errorMsg: 'Invalid File'
		};
	},
	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			csvFileUploads: 'trxdisb/csvFileUploads'
		})
	},
	mounted() {
		this.$store.dispatch('trxdisb/getCSVUploads');
		
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	watch: {
		csvFileUploads: {
			handler(val) {
				this.$emit('update:csvFileUploads', val);
				this.changeUploadDate();
			}
		}
	},
	methods: {
		changeUploadDate(){
			this.csvFileUploads.data.map(det => {
				var date = new Date(det.created_at);
				det['upload_date'] = date.toLocaleDateString();
			});
		},
		handleFileUploads() {
			this.upload_files = this.$refs.files.files;
		},
		verifyFiles(upload_type, upload_files) {
			let upload_header_filename = '';
			let upload_detail_filename = '';
			let upload_type_name = '';

			switch(upload_type) {
			case 'payroll':
				upload_header_filename = 'header';
				upload_detail_filename = 'detail';
				upload_type_name = 'Payroll';
				break;
			case 'trx':
				upload_header_filename = 'header';
				upload_detail_filename = 'detail';
				upload_type_name = 'Transaction';
				break;
			}

			let getUploadHeaderCount = upload_files.filter(file => {
				return file.name.includes(upload_header_filename);
			});

			let getUploadDetailCount = upload_files.filter(file => {
				return file.name.includes(upload_detail_filename);
			});

			if (getUploadHeaderCount.length === 1 && getUploadDetailCount.length === 1) {
				this.isFileValid = true;
			} else {
				this.isFileValid = false;
				this.errorMsg = 'The file selected is invalid or not for '+ upload_type_name +'. Only '+ upload_type_name +' files are accepted.';
			}
		},
		async executeUpload() {
			this.confirmDialog = false;

			let data = [];
			data.upload_file = [];

			for( var i = 0; i < this.upload_files.length; i++ ){
				let file = this.upload_files[i];
				data.upload_file.push(file);
			}

			if (data.upload_file.length > 0 && data.upload_file.length < 2) {
				this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'Only one file has been selected for upload. 2 items are required', 'messagetype': 'error' }, { root: true });
			} else if (data.upload_file.length < 1) {
				this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': 'No Files were uploaded, please Upload 2 Items.', 'messagetype': 'error' }, { root: true });
			} else {
				this.verifyFiles(this.upload_type, data.upload_file);

				if (this.isFileValid === true) {
					let detail_upload_success = false;
					let header_upload_success = false;

					data.upload_file.forEach((file) => {

						let upload_header_filename = '';
						let upload_detail_filename = '';
						
						let upload_file_type = '';

						switch(this.upload_type) {
						case 'payroll':
							upload_header_filename = 'header';
							upload_detail_filename = 'detail';
							upload_file_type = 'Payroll';
							break;
						case 'trx':
							upload_header_filename = 'header';
							upload_detail_filename = 'detail';
							upload_file_type = 'IS';
							break;
						}

						//Upload Details
						if (file.name.includes(upload_detail_filename)) {
							let payload = {
								upload_file: file,
								edit_by: this.currUser.id
							};

							var options = {
								initialFormData: new FormData(),
								showLeafArrayIndexes: true,
								includeNullValues: false,
								mapping: function(value) {
									if (typeof value === 'boolean') {
										return +value ? '1': '0';
									}
									return value;
								}
							};

							var convertedFormData = jsonToFormData(payload, options);
							this.$store.dispatch('trxdisb/uploadDetail'+upload_file_type, convertedFormData).then(response => {
								console.log(response);
								detail_upload_success = true;
							}).catch(error => {
								console.log(error);
								detail_upload_success = false;
							});
						}

						//Upload Headers
						if (file.name.includes(upload_header_filename)) {
							let payload2 = {
								upload_file: file,
								edit_by: this.currUser.id
							};

							var options2 = {
								initialFormData: new FormData(),
								showLeafArrayIndexes: true,
								includeNullValues: false,
								mapping: function(value) {
									if (typeof value === 'boolean') {
										return +value ? '1': '0';
									}
									return value;
								}
							};

							var convertedFormData2 = jsonToFormData(payload2, options2);
							this.$store.dispatch('trxdisb/uploadHeader'+upload_file_type, convertedFormData2).then(response => {
								console.log(response);
								header_upload_success = true;
							}).catch(error => {
								console.log(error);
								header_upload_success = false;
							});

							
							if (header_upload_success === true && detail_upload_success === true) {
								location.reload();				
							}
						}						
					});					
				} else {
					this.$store.commit('app/SET_SNACK_BAR', { 'status': true, 'message': this.errorMsg, 'messagetype': 'error' }, { root: true });
				}
			}
		},
		changeNextData() {
			var nextPage = this.csvFileUploads.current_page + 1;
			this.$store.dispatch('trxdisb/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.csvFileUploads.current_page - 1;
			this.$store.dispatch('trxdisb/getDataPages', prevPage);
		}
	}
};
</script>
<style scoped>
	.upload-files {
		margin-top: 10px; 
	}
</style>