<template>
  <div class="home">
    <upload-from-is></upload-from-is>
  </div>
</template>

<script>
import UploadFromIS from '../../components/pages/trx/upload-is-system/uploadFromIS.vue';
export default {
	components: {
		'upload-from-is': UploadFromIS
	}
};
</script>

<style>
</style>